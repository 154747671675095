//@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
body {
    font-family: 'Roboto', sans-serif;
    color: #2E4350 !important;
}

h1,
.h1 {
    font-size: 1.25rem;
    font-weight: 400;
}
h2,
.h2 {
    font-size: 1rem;
    font-weight: 200;
}
h3,
.h3 {
    font-size: 1.25rem;
    font-weight: 400;
}
h4,
.h4 {
    font-size: 1.25rem;
}
h5,
.h5 {
    font-size: 1.25rem;
}
p {
    font-size: 1rem;
}
a {
    font-size: 1rem;
}

@media (min-width: 480px) {
    h1 {
        font-size: 1.5rem;
        font-weight: 400;
    }
    h2 {
        font-size: 1.25rem;
        font-weight: 200;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 1.75rem;
        font-weight: 400;
    }
    h2 {
        font-size: 1.75rem;
        font-weight: 200;
    }
}

.font-14px {
    font-size: 14px;
}

.font-18px {
    font-size: 18px;
}
