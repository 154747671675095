// Global variables here
// $background-color: #d4d6e4;
// $info-background: #151950;
// $react-color: #53d2fa;
// $link-color: #71a6ff;
// $white: #ffffff;

// $tiger-team: #ff6358;
// $lemon-team: #ffd246;
// $organic-team: #55ab1d;
// $ocean-team: #28b4c8;

$header-filter-color: #2e4350;
$font-blue: #4e8eed;
$other-font-blue: #2e4350;
$secondary: #eaf5fd;
$primary: #5c9efa;
$dark: #d8e0e5;
$main-gray: #f8f9fa;
$font-gray: #2E4350;
$font-grey: #2E4350; // added ? diferent than gray
$grid-header-bg: $secondary;
$font-gray-color: #C3C5C9;
$font-gray-light-color: #767676;
$border-color: #EAEBEC;
$font-strong-color: #1D1F1F;
$font-light-gray: #919191;
$font-collapsable-blue: #196EB1;

//$font-gray: #656565;
