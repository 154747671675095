@import './styles/main.scss';
// @import './styles/material-custom.scss';

@import '~@progress/kendo-theme-default/dist/all.scss';
//@import url('https://cdn.jsdelivr.net/npm/@progress/kendo-theme-default@4.19.0/dist/all.min.css');

// App.scss

// not working when using the npm pkg theme

$slider-width: 650px;

.k-switch-label-on {
    text-align: left;
    left: 8px;
}

.k-switch-label-off {
    text-align: right;
    right: 8px;
}

.mr-4.header-icon {
    font-size: 20px;
    background-color: $primary !important;
    color: white;
}
.mr-4.slide-icon {
    background-color: #00000014 !important;
}

.mr-0.header-icon {
    font-size: 20px;
    background-color: $primary !important;
    color: white;
    margin-right: 0 !important;
}

.container-panel {
    margin-left: 0;
    margin-right: 0;
}

.k-drawer-overlay {
    .k-drawer-content {
        max-width: 100%;
        .container-panel {
            max-width: 100%;
        }
    }
}

.k-drawer-push.no-expanded {
    .k-drawer-content {
        max-width: 100%;
        .container-panel {
            max-width: 100%;
        }
    }
}

.k-drawer-expanded.k-drawer-push {
    .k-drawer-content {
        max-width: calc(100% - 344px);
        .container-panel {
            max-width: 100%;
        }
    }
}

.k-drawer-content {
    height: 100vh;
    background-color: #f7f9fa !important;
}

.header-icon {
    height: 28px !important;
    width: 28px !important;
}

.k-drawer {
    background-color: white;
    border: none;
}

.k-drawer-item:hover {
    background: none;
}

.k-drawer-content {
    background: $main-gray;
}

.input-search {
    height: 28px;
}

.k-grid-header .k-grid-header-sticky,
.k-grid-header .k-grid-header-sticky.k-sorted {
    background: $secondary !important;
}

.text-font-color {
    color: $other-font-blue;
}

.other-blue-font {
    color: #196eb1;
}
.other-gray-font {
    color: #767676;
}

.details-header {
    background: $secondary;
    position: fixed;
    width: 100% !important;
    z-index: 1000;
}

.k-drawer-item span {
    color: $font-blue;
}

.multiselect .k-reset {
    display: inline;
}

.app-font-blue {
    color: $font-blue;
}

.app-font-gray {
    color: $font-gray;
}
.app-font-grey {
    color: $font-grey;
}
.pointer {
    cursor: pointer;
}

.k-button-group > .k-button:focus {
    background-color: $primary !important;
}

.k-drawer-wrapper {
    position: relative;
    .k-drawer-items {
        // max-height: calc(100% - 80px);
        // position: static;
        // overflow: auto;
        // margin-top: 140px;.k-overlay
        margin-bottom: 80px;
    }
    .k-drawer-items {
        max-height: calc(100vh - 80px) !important;
        & > li:first-child {
            position: absolute;
            top: 60px;
            right: 0;
        }
        & > li:nth-child(2) {
            overflow: auto;
            margin-top: 120px;
            max-height: calc(100% - 100px);
        }
    }
}

.k-drawer-overlay .k-overlay {
    display: block !important;
}

.item-drawer-filter {
    font-size: 14px;
    color: $font-blue;
}

.drawer-bottom {
    width: 344px;
    opacity: 1;
    transform: translateX(0px);
    transition: all 200ms ease 0s;
}

.k-draghandle {
    background-image: url('./components/img/containerB.svg');
    background-position: center;
    padding: 6px;
    pointer-events: none;
}
.slider-actual{
    margin-right: -10px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-color: #5c9efa;
    color: #ffffff;
    background-color: #5c9efa;
    border-radius: 50%;
    left: 0%;
}
.slider-estimated{
    margin-left: -10px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border: 1px solid #d4d4d4;
    color: #ffffff;
    background-color: #ebebeb;
    border-radius: 50%;
    left: 100%;
}
.k-tick{
    height: 0px !important;
}

.k-button-decrease > span {
    font-size: 16px;
    position: relative;
    left: -6px;
    margin-right: 0px;
    &:after {
        content: 'O';
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 16px;
        left: 10px;
        //top: -2px;
        position: absolute;
        width: 100%;
    }
}

.k-button-increase > span {
    font-size: 16px;
    position: relative;
    left: 8px;
    margin-right: 0px;
    &:after {
        content: 'D';
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 16px;
        left: -10px;
        //top: -2px;
        position: absolute;
        width: 100%;
    }
}

.k-slider-track-wrap {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.multiselect {
    .k-searchbar {
        width: 100% !important;
    }
}

.app-multiselect-values {
    .k-multiselect-wrap {
        border: 0 !important;
        background: transparent;
        &:hover {
            background: transparent;
        }
    }
}

.k-drawer-overlay .drawer-bottom {
    z-index: 10003;
}

.event-panel-bar-item {
    span.k-header {
        display: flex !important;
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: $dark !important;
        span.k-icon {
            position: static !important;
            margin: 0 !important;
            color: $other-font-blue !important;
        }
    }
}
.w-80 {
    width: 900px;
}
.right-slide {
    overflow-x: hidden;
    overflow-y: auto;
    right: 0;
    height: 100% !important;
    background: white;
    width: $slider-width;
    padding-bottom: 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.08);
}
.main-slide {
    overflow-x: hidden;
    overflow-y: auto;
    left: 0;
    // max-height: 100% !important;
    height: 100% !important;
    background: white;
    width: $slider-width;
    padding-bottom: 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.08);
}
.main-content {
    margin-top: 150;
    height: 100% !important;
    background: white;
    width: 100%;
    padding-bottom: 16px;
    border-left: solid 1px rgba(0, 0, 0, 0.08);
}

.k-drawer-items .k-multiselect {
    font-size: 14px !important;
}

.k-filtercell .k-filtercell-wrapper > .k-textbox {
    font-size: 14px !important;
}

.k-dropdown-operator .k-dropdown-wrap .k-select {
    width: auto;
    height: auto;
}

.k-panelbar > .k-item .k-link {
    height: 40px !important;
    background: #f7f9fa;
}

.k-panelbar > .k-item > .k-link:hover,
.k-panelbar > .k-item > .k-link.k-state-hover {
    background: #f7f9fa;
}

[id~='k-panelbar-item-default-.2'] .k-link.k-header {
    height: auto !important;
}

.k-item.k-state-default .k-link.k-header,
.k-item.k-state-default .k-link {
    color: #656565 !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.k-panelbar .k-item .k-link.k-state-selected {
    color: white !important;
    font-size: 18px;
    font-weight: bold;
}

.k-animation-container {
    z-index: 900 !important;
}

.k-button-clear {
    border-color: transparent !important;
    color: #656565;
    background: none !important;
    box-shadow: none !important;
}

.k-button-clear:focus {
    outline: none;
}

.error-description.k-animation-container {
    z-index: 10200 !important;
    width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.btn-dialog {
    overflow-wrap: break-word;
}

.inner-error-description {
    max-height: 200px;
    overflow-y: scroll;
}

.k-popup {
    padding: 10px;
}

// MODIFIED *********************************************************************
.k-column-list-wrapper.main {
    width: 150px;
}
.k-column-list-wrapper.columns {
    width: 250px;
}
.k-column-list.columns {
    width: 250px;
    height: 400px;
}
.k-columnmenu-actions.columns {
    width: 230px;
}
.k-columnmenu-actions.empty {
    width: 230px;
    margin-top: 3px !important;
}
.vessel-label {
    font-size: 14px !important ;
    font-weight: normal !important;
    margin-left: 10px;
}
.button-tooltip .k-tooltip {
    background-color: rgb(179, 178, 178);
    font-size: 10px;
    color: white;
    max-width: 150px;
    text-align: center;
}
.button-tooltip .k-tooltip .k-callout {
    color: rgb(179, 178, 178);
}
.status-img {
    width: 16px;
    height: 16px;
    background-color: transparent;
    vertical-align: sub !important;
}
.current-location-stepper:before {
    content: '';
    margin-left: -30px;
    margin-top: 3px;
    width: 10px;
    height: 10px;
    background-color: yellow;
    transform: rotate(45deg);
    display: block;
}
.k-stepper .k-progressbar {
    background-color: #4e8eed;
    margin-left: 13px;
    width: 3px;
}
.button-refresh {
    margin-left: 15px;
    border-width: 1px;
    border-style: solid;
    padding-left: 15px;
    border-bottom: transparent;
    border-top: transparent;
    border-right: transparent;
    border-color: lightgrey;
}
.div-button-config {
    border-width: 1px;
    border-style: solid;
    padding-left: 15px;
    border-bottom: transparent;
    border-top: transparent;
    border-right: transparent;
    border-color: lightgrey;
}
.p-version {
    margin-bottom: 5px;
    font-size: x-small;
    color: #a9a9a9;
    text-align: right;
}

.k-step-current .py-2.px-4 {
    border-width: 3px;
    border-left-color: #f7f9fa;
    border-style: solid;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    margin-left: 24px;
    margin-top: -10px;
    margin-bottom: -10px;
    min-width: 380px;
    width: 100%;
}
.py-2.px-4 {
    border-width: 3px;
    border-left-color: #4e8eed;
    border-style: solid;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    margin-left: 24px;
    margin-top: -10px;
    margin-bottom: -10px;
    min-width: 380px;
    width: 100%;
}
.k-icon.k-i-start {
    background-position: center;
    background-repeat: no-repeat;
    background-color: whitesmoke;
    border-radius: 50%;
    z-index: 1;
    font-size: 16px;
    width: 28px;
    height: 28px;
    overflow: visible;
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 11px;
    border-width: 1px;
    border-style: solid;
    border-color: #00000014;
    &:after {
        color: #2e4350;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        font-size: 16px;
        left: 26px;
        position: absolute;
        width: 100%;
        top: 15px;
    }
}
.k-icon.k-i-end {
    background-position: center;
    background-repeat: no-repeat;
    background-color: whitesmoke;
    border-radius: 50%;
    z-index: 1;
    font-size: 16px;
    width: 28px;
    height: 28px;
    overflow: visible;
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 11px;
    border-width: 1px;
    border-style: solid;
    border-color: #00000014 !important;
    &:after {
        color: #2e4350;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 16px;
        left: 9px;
        position: relative;
        width: 100%;
        top: 4px;
    }
}
.k-icon.k-i-actual {
    background-image: url('./components/img/checkmark-icon.svg');
}
.k-icon.k-i-current {
    background-image: url('./components/img/containerW.svg');
}
.k-icon.k-i-estimated {
    background-image: url('./components/img/estimated.svg');
}

.i-route-m6 {
    flex: 1;
    margin-right: 20px;
}

.i-route-m6 > span {
    font-size: 18px;
    font-weight: bold;
}
.f-14 {
    font-size: 14px !important;
    font-weight: normal !important;
}
.f-14.last-event {
    margin-left: -20px;
    color: #FAE64A;
    font-weight: bold !important;
}
.f-14.no-margin {
    color: #FAE64A;
    font-weight: bold !important;
}

.k-step:before{
    content: "";
    right: 20px;
    width: 590px;
    position: absolute;
    border-top:1px solid #d2d3d4;
}
.k-step-label {
    max-width: none !important;
}
.k-step-text {
    max-width: none !important;
}
.k-step-done {
    background-color: #E9E9E9;
}
.k-step-current:before{
    content: "";
    position: absolute;
    border-top: none;
}
.k-step-current {
    background-color: #2A6ECB;
    color: #f7f9fa;
}
.k-i-route {
    background-image: url('./components/img/route-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: whitesmoke;
    border-radius: 50%;
    z-index: 1;
    font-size: 24px;
    width: 28px;
    height: 28px;
    overflow: visible;
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 11px;
    border-width: 1px;
    border-style: solid;
    border-color: #00000014;
}
.i-route-m6 {
    margin-left: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    //font-weight: bold;
}
.step-location-hide {
    display: none;
}
.step-location-link {
    margin-left: 40px;
    min-height: 5px !important;
    font-size: 14px !important;
}
.step-location-link .k-step-text {
    font-size: 14px !important;
}
.k-step-list-vertical .k-step {
    min-height: 8px;
}
.k-step.step-location-link.k-step-link .k-step-indicator {
    margin-right: 0px;
    display: none;
    border-radius: 50%;
    margin: 3px;
    font-size: 24px;
    width: 28px;
    height: 28px;
    border: 0px;
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    overflow: visible;
    transition-property: color, background-color, border-color;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
}
.slide.switch {
    padding-right: 17px;
}
.k-state-disabled {
    opacity: 1;
}
// Container Status icons
.k-i-clock.pending {
    color: #00c400;
}
.k-i-check-outline.updated {
    color: #00c400;
}
.k-i-close-outline.error {
    color: #f70000;
}
.k-i-warning.warning {
    color: orange;
}
// Tree View
.k-icon.k-i-marker-pin-target::before {
    color: whitesmoke;
    font-size: 20px;
}
.k-icon.k-i-marker-pin-target {
    vertical-align: baseline;
}
.k-icon.k-i-check.done::before {
    color: green;
    font-size: 15px;
}
.k-item.k-treeview-item > .k-mid > .k-in > .content {
    margin-left: 30px !important;
}
.k-item.k-treeview-item > .k-mid > .k-in > .header {
    margin-left: 10px !important;
}
.k-treeview-lines {
    padding-inline-start: 0px;
}
.k-treeview .k-item {
    padding: 0;
}
// Button group All/Tracking/Latest
.k-button-group > .k-button:active {
    background-color: #d8e0e5;
}
.k-button-group > .k-button:focus {
    background-color: #d8e0e5;
}
.k-button-76 {
    min-width: 76px;
}
.k-button-header > .k-button:active {
    background-color: #d8e0e5;
}
.k-button-header > .k-button:focus {
    background-color: #d8e0e5;
}
.k-button-config > .k-button:active {
    background-color: #d8e0e5;
}
.k-button-config > .k-button:focus {
    background-color: #d8e0e5;
}
.k-button-config {
    margin-right: 0px !important;
}
.action-header {
    margin-top: 10px !important;
}
.k-button-header-refresh {
    margin-right: 15px !important;
}

.k-filter-row,
.k-filter-row.k-sorted,
.k-filter-row th.k-grid-header-sticky,
.k-filter-row .k-grid-header-sticky.k-sorted {
    background-color: #f0f0f0 !important;
}

span.k-icon,
.k-pager-wrap.k-pager.k-widget.k-grid-pager {
    color: #2e4350;
}

// Grid
.k-grid-header tr:first-child {
    background-color: #eaf5fd !important;
}

.k-grid .k-grid-header .k-header.k-sorted > .k-link,
.k-grid .k-grid-header .k-header.k-sorted > .k-link .k-icon {
    color: #f7f9fa !important;
}

.k-grid .k-grid-header .k-header > .k-link {
    color: #196eb1 !important;
}

.k-grid-content {
    overflow-y: visible;
}

.k-grid-header {
    padding: 0 !important;
}

.k-alt {
    background-color: #f5f5f5;
}

.k-grid .k-alt {
    background-color: rgba(0, 0, 0, 0.06);
}

col.k-sorted {
    background-color: rgba(234, 245, 253, 1) !important;
}
col.k-sorted {
    background-color: rgba(234, 245, 253, 1) !important;
}

th.k-header.k-sorted {
    background-color: rgba(92, 158, 250, 1);
}

th.k-header.k-grid-header-sticky.k-sorted {
    background-color: rgba(92, 158, 250, 1) !important;
}
.k-pager-wrap {
    background-color: #f7f9fa;
}

// .App {
//     text-align: center;
// }

// .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//     height: 40vmin;
//     pointer-events: none;
// }

// .App-header {
//     background-color: #282c34;
//     min-height: 100vh;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     font-size: calc(10px + 2vmin);
//     color: white;
// }

// .App-link {
//     color: #61dafb;
// }

// @keyframes App-logo-spin {
//     from {
//         transform: rotate(0deg);
//     }
//     to {
//         transform: rotate(360deg);
//     }
// }

// .app-header > .project-name {
//     text-align: left;
//     letter-spacing: 0;
//     color: #000000;
//     opacity: 1;
// }

// .app-header > .nav {
//     float: righnpmt;
// }

// .content {
//     min-height: 100vh;
// }

// .logo {
//     font-weight: 700;
//     font-size: 24px;
// }

// .welcome {
//     text-align: center;
//     font-weight: 900;
//     letter-spacing: 0;
//     color: #000000;
//     opacity: 1;
// }

// .sub-header {
//     text-align: center;
//     font-weight: 400;
//     letter-spacing: 0;
//     color: #919191;
//     opacity: 1;
//     margin-bottom: 4rem;
// }

// .get-started {
//     text-align: center;
//     font-weight: 900;
//     font-size: 78px;
//     letter-spacing: 0;
//     color: #000000;
//     opacity: 1;
//     margin-bottom: 1rem;
// }

// .kendoka {
//     width: 12.5rem;
//     height: 12.5rem;
//     opacity: 1;
// }

// .change-theme {
//     font-size: 14px;
//     color: #9098a5;
// }

// .components-list > p > a {
//     margin-left: 0.6em;
// }
// .components-list > p > img {
//     width: 1.25rem;
//     height: 1.3125rem;
// }
// .footer {
//     font-size: 11px;
//     background: #646871 0% 0% no-repeat padding-box;
//     opacity: 1;
//     padding-top: 2rem;
//     padding-bottom: 3rem;
// }

// .footer .links {
//     text-align: left;
//     letter-spacing: 0.33px;
//     color: #ffffff;
//     font-weight: 700;
//     opacity: 1;
// }
// .divider {
//     margin-left: 0.4rem;
//     margin-right: 0.4rem;
//     opacity: 0.2;
// }

// .copyright {
//     letter-spacing: 0.33px;
//     color: #ffffff;
//     opacity: 0.6;
// }

// .k-widget.k-menu {
//     height: 100%;
// }

// .k-widget.k-dropdown,
// .k-list .k-item {
//     text-transform: uppercase;
// }

// .social img {
//     margin-left: 3rem;
// }

.new-event {
    font-size: 11px !important;
    font-weight: 200 !important;
    margin-left: 10px;
}
.error-details {
    text-align: center;
    color: #5c9efa !important;
    font-size: 12px;
    text-decoration: underline !important;
    &:hover {
        cursor: pointer;
    }
}
.input-filters {
    cursor: pointer;
}
.stepper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 0px !important;
    padding-right: 2rem !important;
}
.stepper-header{
    border-width: 3px;
    border-left-color: #4e8eed;
    border-style: solid;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    color: #196EB1;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 14px !important;
    margin-bottom: 0 !important;
    padding-left: 23px;
    padding-top: 22px !important;
    padding-bottom: 22px !important;
}
.stepper-estimated{
    border-left-color: #C3C5C9 !important;
}
.step-tag-estimated{
    background-color: #ECEDF0;
    padding: 4px 15px 4px 15px;
    border-radius: 11px;
    font-size: 12px;
    color: #767676;
    margin-right: 10px;
}
.step-tag-current{
    background-color: #FAE64A;
    padding: 4px 15px 4px 15px;
    border-radius: 11px;
    font-size: 12px;
    font-weight: normal;
    color: #2A6ECB;
    margin-right: 10px;
}
.step-tag-actual{
    background-color: #3489ff;
    padding: 4px 15px 4px 15px;
    border-radius: 11px;
    font-size: 12px;
    color: white;
    margin-right: 10px;
}
.k-step-current{
    .step-tag-actual{
        background-color: white;
        color: #2A6ECB;
    }
}
.rotate-icon {
    transform: rotate(180deg);
}

.cursor-pointer{
    cursor: pointer;
}

.containerInfo{
    justify-content: center;
    display: flex;
    max-width: 1300px;
}
.rightInfo{
    width: 750px;
    overflow-y: auto;
    max-height: 100vh;
    padding-left: 50px;
    padding-right: 0px;
}
.leftInfo{
    width: 600px;
    padding-right: 50px;
    border-right: 1px solid #EAEBEC;
}

.terminal-information-container {
    border-top: 2px solid $border-color;
    padding: 22.5px 0px;
    display: block;

    &__header {
        @include flex-space-between;
        width: 100%;

        &__caption {
            font-size: 16px;
            color: $font-gray-color;

            &__sea {
                color: #c3c5c9;
                font-size: 14px;
                margin-bottom: 0;
            }

            &__sea-link {
                font-size: 12px;
                text-decoration: underline;
                color: #3489ff;
                border: none;
                background: none;

                &:hover {
                    cursor: pointer;
                }
            }
        }
        &__caption.terminal49 {
            color: $font-collapsable-blue;
            font-weight: 600;
        }
        &__sub-caption {
            font-size: 12.8px;
            color: $font-gray-color;
        }
    }

    &__not-available {
        &__header {
            &__text {
                color: #c3c5c9;
                font-size: 14px;
                margin-bottom: 2px;
            }

            &__link {
                margin-bottom: 2px;
                color: #3489ff;
                font-size: 12px;
                text-decoration: underline;
                border: none;
                background: none;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__body {
        .port-of-lading-container {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $border-color;
            padding-top: 16px;
            padding-bottom: 16px;
            margin-top: 16px;

            &__header {
                @include flex-space-between;
                margin-bottom: 16px;

                &__caption {
                    &__header {
                        @include font-strong-color;
                    }

                    &__text {
                        font-weight: normal;
                        @include font-normal-color;
                    }
                }

                &__sub-caption {
                    font-size: 13px;
                    color: $font-gray-light-color;
                    &__terminal-header {
                        color: #1d1f1f;
                        font-weight: bold;
                    }
                }
            }

            &__body {
                @include flex-space-between;

                &__left,
                &__middle,
                &__right {
                    &__content {
                        display: flex;
                        flex-direction: column;
                        &__header {
                            font-size: 14px;
                            color: $font-gray-light-color;
                            font-weight: 600;
                        }
                        &__text {
                            @include font-strong-color;
                        }
                    }
                }
            }
        }

        .hold-at-terminal-container {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $border-color;
            padding-top: 16px;
            padding-bottom: 16px;

            &__header {
                @include flex-space-between;

                &__caption {
                    color: $font-light-gray;
                    font-size: 16px;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                &__line {
                    margin-bottom: 0;
                    &__caption {
                        @include font-strong-color;
                        &::after {
                            content: ':';
                        }
                    }
                    &__sub-caption {
                        font-size: 12px;
                        color: $font-strong-color;
                        margin-left: 2px;
                    }
                    &__sub-caption-status {
                        font-size: 12px;
                        color: $font-light-gray;
                        font-style: italic;
                        margin-left: 2px;
                        &::after {
                            content: ')';
                        }
                        &::before {
                            content: '(';
                        }
                    }
                }
            }
        }

        .fees-container {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $border-color;
            padding-top: 16px;
            padding-bottom: 0;

            &__header {
                @include flex-space-between;
                &__caption {
                    color: $font-light-gray;
                    font-size: 13px;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                &__line {
                    margin-bottom: 2px;
                    @include font-strong-color;
                }
            }
        }
    }
}