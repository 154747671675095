$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: (
        $spacer * 0.25
    ),
    2: (
        $spacer * 0.5
    ),
    3: $spacer,
    4: (
        $spacer * 1.5
    ),
    5: (
        $spacer * 3
    ),
    6: (
        $spacer * 4
    ),
    7: (
        $spacer * 5
    )
) !default;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/buttons';

@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities';
// @import '~bootstrap/scss/card';

$grid-columns: 12;
$grid-gutter-width: 48px;
$grid-column-width: 48px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);
@import "../node_modules/bootstrap/scss/bootstrap";
// @import '~bootstrap/scss/grid';
