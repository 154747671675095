@mixin flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin font-strong-color {
    color: $font-strong-color;
    font-size: 12px;
    font-weight: 600;
}

@mixin font-normal-color {
    color: $font-strong-color;
    font-size: 12px;
    font-weight: normal;
}
