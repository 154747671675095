@use "sass:math";

@import './variables.scss';
@import './mixins.scss';
@import './bootstrap-custom.scss';
@import './typography.scss';

$font-size: 14px;
$line-height: math.div(20, 14);
$input-line-height: math.div(20, 18);

// App Styles
.k-drawer-expanded {
    .k-drawer-item {
        @extend .px-3;
        .k-icon {
            font-size: 20px;
        }
    }

    .header-drawer-filter {
        color: $header-filter-color;
        font-weight: bold;
    }
}

.k-drawer-item {
    .k-icon {
        font-size: 20px;
    }
}

.k-link {
    color: $font-blue !important;
}

// @media (max-width: 575px) {
//     .details-header {
//         .d-flex:nth-child(1) {
//             margin-left: -20px !important;
//         }
//         .d-flex:nth-child(2) {
//             margin-left: 10px !important;
//         }
//     }
// }

// .k-drawer-expanded {
//     .k-drawer-start {
//         @extend .px-3;
//     }
// }

// // App styles
// .k-drawer-container {
//     background-color: $background-color;
//     min-height: calc(100vh - 80px);

//     .k-drawer {
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//         border-right-width: 0 !important;
//     }

//     .k-drawer-wrapper {
//         position: fixed;
//     }

//     &.k-drawer-expanded .k-drawer-wrapper {
//         width: 240px !important;
//     }

//     & .k-drawer-wrapper {
//         width: 50px !important;
//         opacity: 1;
//         transition: all 200ms ease 0s;
//     }

//     .k-drawer-item {
//         user-select: none;

//         &:not(.k-drawer-separator) {
//             padding: 16px 8px;
//         }

//         &.k-state-selected .k-icon {
//             &.dashboard-icon,
//             &.planning-icon,
//             &.profile-icon,
//             &.info-icon {
//                 background-color: #ffffff;
//             }
//         }
//     }
// }
